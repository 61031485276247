<template>
  <div class="row">
    <div class="col-12">
      <card title="Bet History ">
        <div class="col-md-5 pr-md-1">
          <base-select
            :options="optionsArea"
            label="Choose Area"
            v-model="searchInput.areaName"
            name="item-areaName"
          >
          </base-select>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-select
            :options="optionTypeAcc"
            label="Type Account"
            v-model="searchInput.typeAcc"
            name="item-type"
          >
          </base-select>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Nickname"
            v-model="searchInput.nickName"
            name="item-nickName"
          >
          </base-input>
        </div>
        <base-button @click="getListTrade()">Check All</base-button>
        <base-button @click="getListTradeNick()">Search</base-button>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Areaname</th>
                <th>Nickname</th>
                <!-- <th>Account Type</th> -->
                <th>Amount</th>
                <th>Win</th>
                <th>Lose</th>

                <th>Type Bet</th>
                <th>Break Bet</th>
                <th>Result Chart</th>
                <th>Resut Bet</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in paginatedData" :key="index">
                <td>{{ data.areaName }}</td>
                <td>{{ data.nickName }}</td>
                <!-- <td>{{ data.accountType }}</td> -->
                <td>${{ data.amountBet.toFixed(2) }}</td>
                <td>${{ data.amountWin.toFixed(2) }}</td>
                <td>${{ data.amountLose.toFixed(2) }}</td>

                <td v-if="data.betType == 'BUY'" class="green"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#4ab548" transform="scale(1, -1) translate(0, -48)">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg>
</td>
                <td v-if="data.betType == 'SELL'" class="red"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#ff0800">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg></td>

                <td v-if="data.breakType == 'BUY'" class="green">
                  BUY
</td>
                <td v-if="data.breakType == 'SELL'" class="red">
                 SELL
                </td>
                <td v-if="data.breakType == 'NONE'" class="white">
                  NONE
                  </td>

                <td v-if="data.open < data.close" class="green"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#4ab548" transform="scale(1, -1) translate(0, -48)">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg>
</td>
                <td v-if="data.open > data.close" class="red"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#ff0800">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg></td>
                <td v-if="data.open == data.close" class="white"><svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill="#fff" fill-rule="evenodd" d="M3,2 L11,2 C12.3062521,2 13.4175144,2.8348501 13.8293257,4.00008893 L19,4 C20.5976809,4 21.9036609,5.24891996 21.9949073,6.82372721 L22,7 L22,15 C22,16.5976809 20.75108,17.9036609 19.1762728,17.9949073 L19,18 L15,18 C13.6941178,18 12.5831148,17.1656226 12.1710242,16.0009007 L4,16 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M14,6 L14,15 C14,15.5128358 14.3860402,15.9355072 14.8833789,15.9932723 L15,16 L19,16 C19.5128358,16 19.9355072,15.6139598 19.9932723,15.1166211 L20,15 L20,7 C20,6.48716416 19.6139598,6.06449284 19.1166211,6.00672773 L19,6 L14,6 Z M11,4 L4,4 L4,14 L12,14 L12,5 C12,4.48716416 11.6139598,4.06449284 11.1166211,4.00672773 L11,4 Z"/>
</svg></td>

                <td v-if="data.amountWin" class="green">
                  WIN
                </td>
                <td v-if="data.amountLose" class="red">
                 LOSE</td>
                <td v-if="(data.amountWin == '0') & (data.amountLose == '0')">
                 DRAW
                </td>

                <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
                <td v-if="data.status == '1'">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M511.891456 928.549888c229.548032 0 415.634432-186.0864 415.634432-415.634432C927.525888 283.3664 741.439488 97.28 511.890432 97.28 282.343424 97.28 96.258048 283.3664 96.258048 512.915456c0 229.548032 186.084352 415.634432 415.634432 415.634432"
                      fill="#41ba14"
                    />
                    <path
                      d="M436.571136 707.376128l330.3936-330.3936c5.506048-5.507072 8.571904-12.803072 8.633344-20.544512 0.060416-7.85408-2.961408-15.235072-8.511488-20.784128 0.001024-0.012288-0.001024-0.002048-0.001024-0.002048l-0.001024-0.001024c-5.410816-5.409792-12.978176-8.489984-20.687872-8.460288-7.810048 0.032768-15.13984 3.081216-20.640768 8.58112l-309.11488 309.116928-94.99648-94.998528c-5.501952-5.501952-12.833792-8.5504-20.642816-8.58112h-0.115712c-7.69536 0-15.186944 3.08224-20.569088 8.465408-11.360256 11.36128-11.307008 29.899776 0.118784 41.325568l109.924352 109.924352a29.017088 29.017088 0 0 0 4.883456 6.474752c5.658624 5.6576 13.095936 8.482816 20.550656 8.481792a29.31712 29.31712 0 0 0 20.77696-8.604672M511.891456 97.28C282.3424 97.28 96.256 283.3664 96.256 512.915456s186.0864 415.634432 415.635456 415.634432c229.548032 0 415.634432-186.085376 415.634432-415.634432C927.525888 283.365376 741.439488 97.28 511.891456 97.28m0 40.96c50.597888 0 99.661824 9.901056 145.82784 29.427712 44.61056 18.868224 84.683776 45.889536 119.10656 80.31232 34.422784 34.422784 61.444096 74.496 80.313344 119.107584 19.525632 46.164992 29.426688 95.228928 29.426688 145.82784s-9.901056 99.662848-29.426688 145.82784c-18.869248 44.61056-45.89056 84.6848-80.313344 119.107584s-74.496 61.443072-119.10656 80.31232c-46.166016 19.526656-95.229952 29.426688-145.82784 29.426688-50.598912 0-99.662848-9.900032-145.828864-29.426688-44.61056-18.869248-84.683776-45.889536-119.10656-80.31232-34.422784-34.422784-61.444096-74.497024-80.313344-119.107584C147.117056 612.57728 137.216 563.514368 137.216 512.915456s9.901056-99.662848 29.426688-145.82784c18.869248-44.611584 45.89056-84.6848 80.313344-119.107584s74.496-61.444096 119.10656-80.31232C412.228608 148.140032 461.292544 138.24 511.891456 138.24"
                      fill="#000000"
                    />
                  </svg>
                </td>
                <td v-if="data.status == '0'">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M511.891456 928.549888c229.548032 0 415.634432-186.0864 415.634432-415.634432C927.525888 283.3664 741.439488 97.28 511.890432 97.28 282.343424 97.28 96.258048 283.3664 96.258048 512.915456c0 229.548032 186.084352 415.634432 415.634432 415.634432"
                      fill="#ffdd00"
                    />
                    <path
                      d="M436.571136 707.376128l330.3936-330.3936c5.506048-5.507072 8.571904-12.803072 8.633344-20.544512 0.060416-7.85408-2.961408-15.235072-8.511488-20.784128 0.001024-0.012288-0.001024-0.002048-0.001024-0.002048l-0.001024-0.001024c-5.410816-5.409792-12.978176-8.489984-20.687872-8.460288-7.810048 0.032768-15.13984 3.081216-20.640768 8.58112l-309.11488 309.116928-94.99648-94.998528c-5.501952-5.501952-12.833792-8.5504-20.642816-8.58112h-0.115712c-7.69536 0-15.186944 3.08224-20.569088 8.465408-11.360256 11.36128-11.307008 29.899776 0.118784 41.325568l109.924352 109.924352a29.017088 29.017088 0 0 0 4.883456 6.474752c5.658624 5.6576 13.095936 8.482816 20.550656 8.481792a29.31712 29.31712 0 0 0 20.77696-8.604672M511.891456 97.28C282.3424 97.28 96.256 283.3664 96.256 512.915456s186.0864 415.634432 415.635456 415.634432c229.548032 0 415.634432-186.085376 415.634432-415.634432C927.525888 283.365376 741.439488 97.28 511.891456 97.28m0 40.96c50.597888 0 99.661824 9.901056 145.82784 29.427712 44.61056 18.868224 84.683776 45.889536 119.10656 80.31232 34.422784 34.422784 61.444096 74.496 80.313344 119.107584 19.525632 46.164992 29.426688 95.228928 29.426688 145.82784s-9.901056 99.662848-29.426688 145.82784c-18.869248 44.61056-45.89056 84.6848-80.313344 119.107584s-74.496 61.443072-119.10656 80.31232c-46.166016 19.526656-95.229952 29.426688-145.82784 29.426688-50.598912 0-99.662848-9.900032-145.828864-29.426688-44.61056-18.869248-84.683776-45.889536-119.10656-80.31232-34.422784-34.422784-61.444096-74.497024-80.313344-119.107584C147.117056 612.57728 137.216 563.514368 137.216 512.915456s9.901056-99.662848 29.426688-145.82784c18.869248-44.611584 45.89056-84.6848 80.313344-119.107584s74.496-61.444096 119.10656-80.31232C412.228608 148.140032 461.292544 138.24 511.891456 138.24"
                      fill="#000000"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <vs-pagination
          v-model="page"
          :length="pageCount"
          @input="updateData"
        ></vs-pagination>
      </card>
    </div>
    <div class="col-12">
      <card title="Check profit ">
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Nickname"
            v-model="adminInput.nickName"
            name="item-nickName"
          >
          </base-input>
        </div>

        <div class="col-md-5 pr-md-1">
          <base-input
            type="datetime-local"
            label="From Time"
            v-model="adminInput.fromTime"
            name="item-fromTime"
            :value="defaultFromDate"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            type="datetime-local"
            label="Đếnlúc"
            v-model="adminInput.toTime"
            name="item-toTime"
            :value="defaultFromDate"
          >
          </base-input>
        </div>
        <base-button @click="getProfit()">Check</base-button>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Total cược"
            v-model="dataProfit.bet"
            name="item-nickName"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Total win"
            v-model="dataProfit.win"
            name="item-nickName"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Total lose"
            v-model="dataProfit.lose"
            name="item-nickName"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Total profit"
            v-model="dataProfit.profit"
            name="item-nickName"
          >
          </base-input>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from "./services/AuthenticationService";
const tableColumns = [
  "AreaName",
  "NickName",
  "From",
  "Amount",
  "Time",
  "Status",
];
import BaseSelect from "../components/BaseSelect";

export default {
  components: {
    BaseTable,
    "base-select": BaseSelect,
  },
  props: {},
  data() {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const monthYesterday = (yesterday.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dateYesterday = yesterday.getDate().toString().padStart(2, "0");
    const fromTime = `${yesterday.getFullYear()}-${monthYesterday}-${dateYesterday}T07:00`;

    const monthToday = (now.getMonth() + 1).toString().padStart(2, "0");
    const dateToday = now.getDate().toString().padStart(2, "0");
    const toTime = `${now.getFullYear()}-${monthToday}-${dateToday}T07:00`;
    return {
      datatrade: [],
      itemsPerPage: 10,
      page: 1,
      optionsArea: [{ value: "BIT", label: "BITTRADE" }],
      searchInput: {
        areaName: "ALL",
        nickName: "",
        typeAcc: "ALL"
      },
      
      optionTypeAcc: [
        {value: "1", label: "Tất cả"},
        {value: "2", label: "Tài khoản thường"},
        {value: "3", label: "Tài khoản MKT"}
      ],
      areaName: "",
      dataInput: [],
      result: "",
      adminInput: {
        name: "",
        fromTime: fromTime,
        toTime: toTime,
      },
      dataProfit: [],
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.datatrade.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datatrade.slice(start, end);
    },
    defaultFromDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const time = "07:00 AM";
      return `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}T${time}`;
    },
  },
  methods: {
    getListTrade() {
      let obj = {
        areaName: this.searchInput.areaName,
        
        typeAcc: this.searchInput.typeAcc
      };
      console.log(obj);
      
      AuthenticationService.getListTrade(obj)
      .then((resp) => {
        if (resp.data.success) {
          this.datatrade = resp.data.data;
          console.log(this.datatrade);

          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "#4B0082",
          });
        }
        if (
          resp.data.success === false &&
          resp.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/hadaleon").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        }
      });
    },
    getListTradeNick() {
      let obj = {
        areaName: this.searchInput.areaName,
        nickName: this.searchInput.nickName,
        typeAcc: this.searchInput.typeAcc
      };
      AuthenticationService.getListTradeNick(obj).then((resp) => {
        if (resp.data.success) {
          this.datatrade = resp.data.data;
          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "#4B0082",
          });
        }
        if (
          resp.data.success === false &&
          resp.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/hadaleon").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        }
      });
    },
    updateData(page) {
      this.page = page;
    },
    getProfit() {
      let obj = {
        nickName: this.adminInput.nickName,
        fromTime: this.adminInput.fromTime,
        toTime: this.adminInput.toTime,
      };

      AuthenticationService.checkProfit(obj)
        .then((resp) => {
          if (resp.data.success) {
            this.dataProfit = resp.data.data;
            return this.$vs.notification({
              text: "Đã cập nhập thông tin thành công ",
              color: "#4B0082",
            });
          } else if (
            resp.data.success === false &&
            resp.data.errorType == "invalidAccessToken"
          ) {
            this.$router.push("/hadaleon").catch(() => {});
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: "Phiên đăng nhập đã hết hạn! ",
              color: "#4B0082",
            });
          } else if (resp.data.errorType == "Usermarketing") {
            return this.$vs.notification({
              text: "Nickname không hợp lệ! ",
              color: "#4B0082",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$vs.notification({
            text: "Nickname không hợp lệ!",
            color: "#4B0082",
          });
        });
    },
  },
};
</script>
<style>
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  color: #fff;
}

.table th {
  font-weight: bold;
  background-color: #00172e;
  border-bottom: 2px solid #dee2e6;
  color: #fff !important;
}

.table tbody tr:nth-child(even) {
  background-color: #070025;
}

.green {
  color: rgb(0, 255, 0) !important;
}

.red {
  color: red !important;
}
.white {
  color: white !important;
}
</style>
