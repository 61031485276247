<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card class="card-size">
    <h5 slot="header" class="title">Edit Profile</h5>

    <div class="row mb-3">
      <div class="col-md-5 pr-md-1">
        <base-select :options="optionsArea" label="Area Name" v-model="searchInput.areaName"
          name="item-areaName"></base-select>
      </div>
      <div class="col-md-5 pr-md-1">
        <base-input label="Nick Name" v-model="searchInput.nickName" name="item-nickName"></base-input>
      </div>
      <div class="col-md-2 mt-4">
        <base-button @click="getAllMember">Search</base-button>
      </div>
    </div>

    <div class="table-responsive mb-3">
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Area Name</th>
            <th>Nick Name</th>
            <th>USDT</th>
            <th>Active</th>
            <th>Verify</th>
            <th>Marketing</th>
            <th>Ref Code</th>
            <th>Upline ID</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in paginatedData" :key="index">
            <td>{{ data.id }}</td>
            <td>{{ data.areaName }}</td>
            <td>{{ data.nickName }}</td>
            <td>{{ data.balance }}</td>


            <td v-if="data.activate == 0">Chưa Active</td>
            <td class="green" v-if="data.activate == 1">Đang hoạt động</td>


            <td class="blue" v-if="data.verify == 0">Khách hàng mới</td>
            <td class="yellow" v-if="data.verify == 1">Đang chờ duyệt</td>
            <td class="green" v-if="data.verify == 2">Đã Verify</td>

            <td class="green" v-if="data.marketing == 0">Người dùng</td>
            <td class="yellow" v-if="data.marketing == 1">Markerter</td>

            <td>{{ data.refCode }}</td>
            <td>{{ data.uplineId }}</td>
            <td>
              <vs-button @click="getUserInfo(data)">Edit</vs-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-md-4">
        <vs-pagination v-model="page" :length="pageCount" @input="updateData"></vs-pagination>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-4">
        <base-input label="ID" v-model="dataUser.id" name="item-Id"></base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input label="Email" v-model="dataUser.email" name="item-Email"></base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input label="Nick Name" v-model="dataUser.nickName" name="item-nickname"></base-input>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6 pr-md-1">
        <base-input label="Họ" v-model="dataUser.firstName" name="item-last_name"></base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input label="Tên" v-model="dataUser.lastName" name="item-first_name"></base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input :readonly="true" label="Ref Code" v-model="dataUser.refCode" name="item-refCode"></base-input>
      </div>
      <div class="col-md-6 pl-md-1" v-if="dataUser.marketing == false">
        <base-input label="Upline UID" v-model="dataUser.uplineUid" name="item-uplineId"></base-input>
      </div>
      <div class="col-md-6 pl-md-1" v-if="dataUser.marketing == false">
        <base-input label="Upline Nick Name" v-model="dataUser.UpNick" name="item-uplineId"></base-input>
      </div>
      <div class="col-md-6 pl-md-1" v-if="dataUser.marketing == false">
        <base-input label="Upline ID" v-model="dataUser.uplineId" name="item-uplineId"></base-input>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6">
        <vs-checkbox v-model="dataUser.vipUser" @change="handleChange">
          THÀNH VIÊN VIP
        </vs-checkbox>
      </div>
    </div>

    <div v-if="dataUser.vipUser" class="mt-2">
      <div class="row">
        <div class="col-md-6">
          <base-select :options="optionsLevel" label="Level VIP" v-model="dataUser.levelVip"
            name="levelVip"></base-select>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input type="date" label="Expiration Date" v-model="dataUser.vipLevelExpTime"></base-input>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4">
        <vs-checkbox label="Marketing" v-if="dataUser.uplineUid == 0" v-model="dataUser.marketing"
          @change="handleChangeMKT">Marketing</vs-checkbox>
        <vs-checkbox v-else label="Marketing" v-model="dataUser.marketing" aria-placeholder="MKT"
          aria-readonly="true">Tài khoản đã Active MKT</vs-checkbox>
      </div>
      <div v-if="dataUser.marketing" class="col-md-4 px-md-1">
        <base-input type="number" label="Add Money" v-model="amountmkt" name="addmoney"></base-input>
        <base-button @click="AddMoneyMKT()">ADD</base-button>
      </div>
    </div>



    <div class="row mt-3">
      <div class="col-md-8">
        <div class="row">
          <vs-checkbox class="col-md-6" v-model="dataUser.activate" @change="handleChangeActivate">ACTIVE</vs-checkbox>
          <vs-checkbox class="col-md-6" v-model="dataUser.lockStatus" @change="handleChangeLock">Lock</vs-checkbox>
          <vs-checkbox class="col-md-6" v-model="dataUser.blacklist" @change="handleChangeBlack">Blacklist</vs-checkbox>
          <vs-checkbox class="col-md-6" v-model="dataUser.verify" @change="handleChangeVerify">Verify
            (KYC)</vs-checkbox>
          <vs-checkbox class="col-md-6" v-model="dataUser.activate2FA" @change="handleChangeMKT">2FA</vs-checkbox>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <base-button @click="UpdateUser()">Save</base-button>
    </div>
    <card>
      <div>
        <div class="row mb-3">
          <div class="row balance mt-3">

            <div class="col-md-5 pr-md-1">
              <base-input :readonly="true" label="Balance" v-model="dataUser.balance" name="Balance"></base-input>
            </div>
            <div class="col-md-4 px-md-1" v-if="dataUser.order && dataUser.order.length >= 2">
              <base-input label="Balance Demo" v-model="dataUser.order[0].balance" name="BalanceDemo"></base-input>
            </div>
            <div class="col-md-4 px-md-1" v-if="dataUser.order && dataUser.order.length >= 2">
              <base-input label="Balance Live" v-model="dataUser.order[1].balance" name="BalanceLive"></base-input>
            </div>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-select :options="optionsArea" label="Area Name" v-model="searchInput.areaName"
              name="item-areaName"></base-select>
          </div>
          
          <div class="col-md-2 mt-4">
            <base-button @click="getAllMember">Add</base-button>
            <base-button @click="getAllMember">Remove</base-button>
          </div>
        </div>
      </div>
    </card>
  </card>
</template>

<script>
import AuthenticationService from '../services/AuthenticationService';
import { VueSelect, VueSelectItem } from 'vue-select'
import BaseDropdown from '../../components/BaseDropdown.vue';
import BaseSelect from '../../components/BaseSelect.vue';
const tableColumns = ["AreaName", "NickName", "From", "Amount", "Time", "Status"];
// import Settings from "../settings.json";
import { BaseTable } from "@/components";
export default {
  props: {
    data: {
      type: Object,
      default: () => { }

    }
  },
  components: {
    'vs-select': VueSelect,
    'vs-select-item': VueSelectItem,
    'base-select': BaseSelect,
    BaseTable,

  },
  data() {
    return {
      dataverify: [],
      itemsPerPage: 10,
      page: 1,
      searchInput: {
        areaname: "ALL",
      },
      optionsArea: [
        { value: "ALL", label: "ALL" },
        { value: "BIT", label: "BIT" },
      ],
      pageCount() {
        return Math.ceil(this.dataUserList.length / this.itemsPerPage);
      },
      paginatedData() {
        const start = (this.page - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.dataUserList.slice(start, end);
      },
      dataUser: {
        marketing: false,
        vipUser: false,
        levelVip: 1,
        active: false,
        lockStatus: false,
        activate2FA: false,
        secret2FA: "",
        vipLevelExpTime: "",
        order: [],
        amount: 0,
        UpNick: "",
        verify: false,
        blacklist: false,
      },
      dataUserList: {},
      searchInput: {
        areaName: '',
        nickName: ''
      },
      amountmkt: 0,
      email: '',
      userData: null,
      updatedFields: {},
      hiddenOption: null,
      optionsArea: [
        { value: "ALL", label: "ALL" },
        { value: "BIT", label: "BIT" },
      ],
      optionsLevel: [
        { value: "0", label: "Level 0" },
        { value: "1", label: "Level 1" },
        { value: "2", label: "Level 2" },
        { value: "3", label: "Level 3" },
        { value: "4", label: "Level 4" },
        { value: "5", label: "Level 5" },
        { value: "6", label: "Level 6" },
        { value: "7", label: "Level 7" }
      ],
    }
  },
  methods: {

    handleChange() {
      this.dataUser.vipUser = this.dataUser.vipUser ? true : false;
    },
    handleChangeMKT() {
      if (this.dataUser.marketing === false) {
        this.dataUser.marketing = true; // Enable the checkbox
      } else {
        this.dataUser.marketing = true; // Lock the checkbox in the checked state
      }
    },
    handleChangeActivate() {
      this.dataUser.activate = this.dataUser.activate ? true : false;
    },
    handleChangeLock() {
      this.dataUser.lockStatus = this.dataUser.lockStatus ? true : false;
    },
    handleChangeBlack() {
      this.dataUser.blacklist = this.dataUser.blacklist ? true : false;
    },
    handleChangeVerify() {
      if (this.dataUser.verify) {
        this.dataUser.verify = false;
      } else {
        this.dataUser.verify = 2;
      }

    },
    handleChangeActivate2FA() {
      this.dataUser.activate2FA = this.dataUser.activate2FA ? true : false;
    },
    formatPrice(value, minimum) {
      var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minimum
      });
      return formatter.format(value);
    },
    getUserInfo(data) {
  console.log(obj); // Log the constructed object for debugging

  const obj = {
    nickName: data.nickName?.toLowerCase(), // Use optional chaining to handle potential undefined nickName
    areaName: data.areaName,
  };

  // Handle potential errors gracefully using try...catch
  try {
    return AuthenticationService.getUserInfo(obj)
      .then((res) => {
        if (res.data.success === true) {  
          this.dataUser = res.data;
          return res; // Return the entire response for potential further processing
        } else {
          throw new Error(`Authentication service error: ${res.data.message}`);
        }
      })
      .catch((error) => {
        console.error('Error fetching user info:', error);
        // Handle error gracefully, e.g., display an error message to the user
        this.$emit('error-fetching-user-info', error.message); // Emit an event for other components to handle
      });
  } catch (error) {
    console.error('Unexpected error:', error);
    // Handle unexpected errors gracefully, e.g., display a generic error message
    this.$emit('unexpected-error', error.message); // Emit an event for other components to handle
  }
},
    getAllMember() {
      const obj = {
        nickName: this.searchInput.nickName.toLowerCase(),
        areaName: this.searchInput.areaName,
      }
      console.log(obj);
      // AuthenticationService.getAllMember(obj)
      //   .then((res) => {
      //     if (res.data.success === true) {
      //       this.dataUser = res.data;
      //       return this.$vs.notification({
      //         text: ' Thành công ',
      //         color: '#4B0082',
      //       });
      //     } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
      //       this.$router.push('/hadaleon').catch(() => { })
      //       localStorage.removeItem("accessToken");
      //       localStorage.removeItem("refreshToken");
      //       localStorage.removeItem("INFO");
      //       return this.$vs.notification({
      //         text: 'Phiên đăng nhập đã hết hạn! ',
      //         color: '#4B0082',
      //       });
      //     } 
      //   });
      AuthenticationService.getListUser(obj)
        .then((res) => {
          if (res.data.success === true) {
            this.dataUserList = res.data.data;
            this.paginatedData = this.dataUserList;
            console.log(this.dataUserList);


            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
    },

    UpdateUser() {
      const obj = {
        id: this.dataUser.id,
        areaName: this.searchInput.areaName,
        nickName: this.dataUser.nickName.toLowerCase(),
        email: this.dataUser.email.toLowerCase(),
        firstName: this.dataUser.firstName,
        lastName: this.dataUser.lastName,
        refCode: this.dataUser.refCode,
        uplineUid: this.dataUser.uplineUid,
        uplineId: this.dataUser.uplineId,
        vipUser: this.dataUser.vipUser,
        levelVip: this.dataUser.levelVip,
        balance: this.dataUser.balance,
        marketing: this.dataUser.marketing,
        commission: this.dataUser.commission,
        order: this.dataUser.order,
        vipLevelExpTime: this.dataUser.vipLevelExpTime,
        levelVip: this.dataUser.levelVip,
        activate: this.dataUser.activate,
        activate2FA: this.dataUser.activate2FA,
        secret2FA: this.dataUser.secret2FA,
        verify: this.dataUser.verify,
        lockStatus: this.dataUser.lockStatus,
        blacklist: this.dataUser.blacklist
      }

      AuthenticationService.updateUserInfo(obj)
        .then((resp) => {
          if (resp.data.success) {
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',

            });
          } else if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } else if (resp.data.success === false && resp.data.errorType == "nickNameAlreadyExists") {
            return this.$vs.notification({
              text: 'Nickname đã tồn tại! ',
              color: '#4B0082',
            });
          } else {
            return this.$vs.notification({
              text: "Không thể cập nhật thông tin !",
              color: '#4B0082',

            });
          }
        })
    },
    AddMoneyMKT() {
      let amount = Number(this.amountmkt);
      const obj = {
        amount: amount,
        id: this.dataUser.id,
      }
      AuthenticationService.sendBalanceMarketing(obj)
        .then((resp) => {
          if (resp.data.success) {
            this.dataUser.uplineUid = null
            this.dataUser.uplineId = null
            this.dataUser.balance = Number(this.amountmkt) + Number(this.dataUser.balance);

            return this.$vs.notification({
              text: 'Đã bơm tiền thành công!',
              color: 'success',
              iconPack: 'feather',
              icon: 'icon-check'
            });

          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } else {
            return this.$vs.notification({
              text: "Không thể cập nhật thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })

    }
  },
  computed: {
    levelVipValue() {
      return this.dataUser.vipUser ? this.dataUser.levelVip : '';
    },
    marketingValue() {
      return this.dataUser.vipUser ? this.dataUser.marketing : '';
    },
    expirationDateValue() {
      return this.dataUser.vipUser ? this.dataUser.vipLevelExpTime : '';
    },
    verifyValue() {
      return this.dataUser.verify ? this.dataUser.verify : '';
    },
    blackValue() {
      return this.dataUser.blacklist ? this.dataUser.blacklist : '';
    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.dataUserList.slice(start, end);
    },
  }
}
</script>
<style>
<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card-size {
 
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.input-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.base-button {
  background-color: #007bff;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.base-button:hover {
  background-color: #0056b3;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  border-radius: 8px;
  overflow: hidden;
}

.table th,
.table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.table th {

  font-weight: bold;
}



.green {
  color: #28a745;
  font-weight: bold;
}

.yellow {
  color: #ffc107;
  font-weight: bold;
}

.blue {
  color: #007bff;
  font-weight: bold;
}

.vs-pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.vs-pagination .vs-page {
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
 
  border-radius: 5px;
  transition: background-color 0.3s;
}

.vs-pagination .vs-page.active {
  background-color: #007bff;
  color: #fff;
}



.vs-checkbox,
.vs-checkbox label {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .col-md-5,
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>


  