<template>
    <div class="row">
      <div class="col-12">
        <card title="Invest History ">
          <!-- <div class="col-md-5 pr-md-1">
            <base-select
              :options="optionsArea"
              label="Choose Area"
              v-model="searchInput.areaName"
              name="item-areaName"
            >
            </base-select>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-select
              :options="optionTypeAcc"
              label="Type Account"
              v-model="searchInput.typeAcc"
              name="item-type"
            >
            </base-select>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Nickname"
              v-model="searchInput.nickName"
              name="item-nickName"
            >
            </base-input>
          </div> -->
          <base-button @click="getInvestHistory()">Check All</base-button>
          <!-- <base-button @click="getListTradeNick()">Search</base-button> -->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Areaname</th>
                  <th>nickName</th>
                  <th>Package Type</th>
                  <th>Package Value</th>
                  <th>Tháng</th>
                  <th>Đã được</th>
                  <th>status</th>
                  
                  <th>createdAt</th>
                  <!-- <th>updatedAt</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedData" :key="index">
                  <td>{{ data.id }}</td>
                  <td>{{ data.areaName }}</td>    
                  <td>{{ data.nickName }}</td>
                  <td v-if="data.packageType === 'bit1'" class="bronze"> BIT1</td>
                  <td v-if="data.packageType === 'bit2'" class="silver"> BIT2</td>
                  <td v-if="data.packageType === 'bit3'" class="gold"> BIT3</td>
                  <td v-if="data.packageType === 'bit4'" class="platinum"> BIT4</td>
                  <td v-if="data.packageType === 'bit5'" class="diamond"> BIT5</td>
                  <td v-if="data.packageType === 'bit6'" class="challenger"> BIT6</td>
                  <td v-if="data.packageType === 'bit1'"> $100</td>
                  <td v-if="data.packageType === 'bit2'"> $500</td> 
                  <td v-if="data.packageType === 'bit3'"> $1,000</td> 
                  <td v-if="data.packageType === 'bit4'"> $3,000</td> 
                  <td v-if="data.packageType === 'bit5'"> $5,000</td> 
                  <td v-if="data.packageType === 'bit6'"> $10,000</td>   
                  <td>{{ data.duration }}M</td>
                  <td>{{ data.currentDay }} Ngày</td>
                  <td class="green" v-if="data.status === 0">Active</td>
                  <td class="blue" v-if="data.status === 1">Finish</td>
                  <td class="red" v-if="data.status === 2">Cancle</td>
                  
                  <td>{{ formatDate(data.createdAt) }}</td>
                  <!-- <td>{{ formatDate(data.updatedAt) }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <vs-pagination
            v-model="page"
            :length="pageCount"
            @input="updateData"
          ></vs-pagination>
        </card>
      </div>
      <!-- <div class="col-12">
        <card title="Check profit ">
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Nickname"
              v-model="adminInput.nickName"
              name="item-nickName"
            >
            </base-input>
          </div>
  
          <div class="col-md-5 pr-md-1">
            <base-input
              type="datetime-local"
              label="From Time"
              v-model="adminInput.fromTime"
              name="item-fromTime"
              :value="defaultFromDate"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              type="datetime-local"
              label="Đếnlúc"
              v-model="adminInput.toTime"
              name="item-toTime"
              :value="defaultFromDate"
            >
            </base-input>
          </div>
          <base-button @click="getProfit()">Check</base-button>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Total cược"
              v-model="dataProfit.bet"
              name="item-nickName"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Total win"
              v-model="dataProfit.win"
              name="item-nickName"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Total lose"
              v-model="dataProfit.lose"
              name="item-nickName"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Total profit"
              v-model="dataProfit.profit"
              name="item-nickName"
            >
            </base-input>
          </div>
        </card>
      </div> -->
    </div>
  </template>
  <script>
  import { BaseTable } from "@/components";
  import AuthenticationService from "./services/AuthenticationService";
  const tableColumns = [
    "AreaName",
    "NickName",
    "From",
    "Amount",
    "Time",
    "Status",
  ];
  import BaseSelect from "../components/BaseSelect";
  
  export default {
    components: {
      BaseTable,
      "base-select": BaseSelect,
    },
    props: {},
    data() {
      const now = new Date();
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      const monthYesterday = (yesterday.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const dateYesterday = yesterday.getDate().toString().padStart(2, "0");
      const fromTime = `${yesterday.getFullYear()}-${monthYesterday}-${dateYesterday}T07:00`;
  
      const monthToday = (now.getMonth() + 1).toString().padStart(2, "0");
      const dateToday = now.getDate().toString().padStart(2, "0");
      const toTime = `${now.getFullYear()}-${monthToday}-${dateToday}T07:00`;
      return {
        datatrade: [],
        itemsPerPage: 10,
        page: 1,
        optionsArea: [{ value: "BIT", label: "BITTRADE" }],
        searchInput: {
          areaName: "ALL",
          nickName: "",
          typeAcc: "ALL"
        },
        
        optionTypeAcc: [
          {value: "1", label: "Tất cả"},
          {value: "2", label: "Tài khoản thường"},
          {value: "3", label: "Tài khoản MKT"}
        ],
        areaName: "",
        dataInput: [],
        result: "",
        adminInput: {
          name: "",
          fromTime: fromTime,
          toTime: toTime,
        },
        dataProfit: [],
      };
    },
    computed: {
      pageCount() {
        return Math.ceil(this.datatrade.length / this.itemsPerPage);
      },
      paginatedData() {
        const start = (this.page - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.datatrade.slice(start, end);
      },
      defaultFromDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const day = now.getDate();
        const time = "07:00 AM";
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}T${time}`;
      },
    },
    methods: {
        formatDate(dateString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit',minute: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('vi-VN', options);
        },
        getInvestHistory() {
        AuthenticationService.getInvestHistory()
        .then((resp) => {
          if (resp.data.success) {
            this.datatrade = resp.data.data;
            console.log(this.datatrade);
  
            return this.$vs.notification({
              text: "Đã cập nhập thông tin thành công ",
              color: "#4B0082",
            });
          }
          if (
            resp.data.success === false &&
            resp.data.errorType == "invalidAccessToken"
          ) {
            this.$router.push("/hadaleon").catch(() => {});
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: "Phiên đăng nhập đã hết hạn! ",
              color: "#4B0082",
            });
          }
        });
      },
      
      updateData(page) {
        this.page = page;
      },
      
    },
  };
  </script>
  <style>
  .table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    color: #fff;
  }
  
  .table th {
    font-weight: bold;
    background-color: #00172e;
    border-bottom: 2px solid #dee2e6;
    color: #fff !important;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #070025;
  }
  
  .green {
    color: rgb(0, 255, 0) !important;
  }
  
  .red {
    color: red !important;
  }
  .white {
    color: white !important;
  }
  .bronze {
    color: rgb(190, 140, 0) !important
  }
  .silver {
    color: silver !important
}
.gold {
    color: rgb(255, 230, 0) !important
}
.platinum {
    
    color: paleturquoise !important
}
.diamond {
    color: rgb(255, 81, 0) !important
}
.challenger {
    color: rgb(212, 0, 255) !important
}
  </style> 
  