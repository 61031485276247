<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>

    <h5 slot="header" class="title">Check Volume and Commission</h5>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsArea" label="Choose Area" v-model="searchInput.areaName" name="item-areaName">

      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-input label="Nickname" v-model="searchInput.nickName" name="item-nickName">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-input type="datetime-local" label="From Time" v-model="searchInput.fromTime" name="item-fromTime" :value="defaultFromDate">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-input type="datetime-local" label="To Time" v-model="searchInput.toTime" name="item-toTime" :value="defaultFromDate">
      </base-input>
      <base-button @click=checkVolume()>Check Volume Trade</base-button>
      <base-button @click=checkCommission()>Check hoa hồng</base-button>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Commission" v-model="dataCom" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="VIP Commission" v-model="dataVipCom" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F1" v-model="dataUser.volumeF1s" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F2" v-model="dataUser.volumeF2s" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F3" v-model="dataUser.volumeF3s" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F4" v-model="dataUser.volumeF4s" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F5" v-model="dataUser.volumeF5s" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F6" v-model="dataUser.volumeF6s" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F7" v-model="dataUser.volumeF7s" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade F1-F7" v-model="dataUser.volumetotal" name="Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade Fs" v-model="dataUser.volumeFs" name="Commission">
      </base-input>
    </div>

  </card>
</template>
<script>
import AuthenticationService from '../services/AuthenticationService';
import { VueSelect, VueSelectItem } from 'vue-select'
import BaseDropdown from '../../components/BaseDropdown.vue';
import BaseSelect from '../../components/BaseSelect.vue';

export default {
  props: {
    data: {
      type: Object,
      default: () => { }

    }
  },
  components: {
    'vs-select': VueSelect,
    'vs-select-item': VueSelectItem,
    'base-select': BaseSelect,


  },
  data() {
        const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const monthYesterday = (yesterday.getMonth() + 1).toString().padStart(2, '0');
    const dateYesterday = yesterday.getDate().toString().padStart(2, '0');
    const fromTime = `${yesterday.getFullYear()}-${monthYesterday}-${dateYesterday}T07:00`;

    const monthToday = (now.getMonth() + 1).toString().padStart(2, '0');
    const dateToday = now.getDate().toString().padStart(2, '0');
    const toTime = `${now.getFullYear()}-${monthToday}-${dateToday}T07:00`;
    return {
      // defaultFromDate: new Date().toISOString().slice(0, 10) + 'T07:00',
      searchInput: {
        areaName: '',
        fromTime: fromTime,
        toTime: toTime,
  //  fromTime: "2023-04-01T07:00",
        // toTime: "2023-04-01T07:00",
        nickName: "",
      },
      dataUser: {
        commission: 0,
        vipCommission: 0,
        volumeF1s: '',
        volumeF2s: '',
        volumeF3s: '',
        volumeF4s: '',
        volumeF5s: '',
        volumeF6s: '',
        volumeF7s: '',
        volumeFs: '',
        volumetotal: 0,
      },
      dataCom: "",
      dataVipCom: "",
      optionsArea: [
        { value: "BIT", label: "BITTRADE" },
      ],

    }
  },
  methods: {


    checkVolume() {
      const obj = {
        nickName: this.searchInput.nickName.toLowerCase(),
        areaName: this.searchInput.areaName,
        fromTime: this.searchInput.fromTime,
        toTime: this.searchInput.toTime,
      }

      AuthenticationService.getInfoVolumeRanksByTime(obj)
        .then((res) => {
          if (res.data.success === true) {
            this.dataUser = res.data.data;
            let { volumeF1s, volumeF2s, volumeF3s, volumeF4s, volumeF5s, volumeF6s, volumeF7s } = this.dataUser;
            this.dataUser.volumetotal = volumeF1s + volumeF2s + volumeF3s + volumeF4s + volumeF5s + volumeF6s + volumeF7s;
            this.dataUser.volumeFs += this.dataUser.volumetotal;
            return this.$vs.notification({
              text: ' Thành công ',
              color: '#4B0082',
            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } else {
            return this.$vs.notification({
              text: 'Nickname không tồn tại ! ',
              color: '#4B0082',
            });
          }
        })
    },
    checkCommission() {
      const obj = {
        nickName: this.searchInput.nickName.toLowerCase(),
        areaName: this.searchInput.areaName,
        fromTime: this.searchInput.fromTime,
        toTime: this.searchInput.toTime,
      }

      AuthenticationService.getInfoTradingCommissionRanksByTime(obj)
        .then((res) => {
          if (res.data.success === true) {
            this.dataUser = res.data.data;
            let sumCommission = 0;
            let sumVipCommission = 0;
            for (let i = 0; i < this.dataUser.length; i++) {
              sumCommission += this.dataUser[i].commission;
              sumVipCommission += this.dataUser[i].vipCommission;
            }
            this.dataCom = sumCommission; // Set dataCom to sumTradingVolume
            this.dataVipCom = sumVipCommission;
            return this.$vs.notification({
              text: ' Thành công ',
              color: '#4B0082',
            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } else {
            return this.$vs.notification({
              text: 'Nickname không tồn tại ! ',
              color: '#4B0082',
            });
          }
        })
    }


  },
  computed: {
    defaultFromDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const time = "07:00 AM";
      return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T${time}`;
    },
  },
}
</script>
<style>
.form-control {
  color: #fff !important;
}
</style>
