<template>
  <div class="row">
    <div class="col-12">
      <card title="Verify KYC ">
        <div class="col-md-4 px-md-1">
          <base-select :options="optionsArea" label="Choose Area" v-model="searchInput.areaName" name="item-areaName">

          </base-select>
        </div>
        <base-button @click=getVerify()>Verify</base-button>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Areaname</th>
                <th>Nickname</th>
                <th>CID Front</th>
                <th>CID Back</th>
                <th>Status</th>
                <th>Verify</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in paginatedData" :key="index">
                <td>{{ data.id }}</td>
                <td>{{ data.areaName }}</td>
                <td>{{ data.nickName }}</td>
                <td>
                  <img style="width:50px" :src="`${dm}admin/getCid/${data.cidFront}`" />
                </td>
                <td>
                  <img style="width:50px" :src="`${dm}admin/getCid/${data.cidBack}`" />
                </td>
                <td>{{ data.status }}</td>
                <vs-button class="green" @click="AcceptVerify(data)">Accept</vs-button>
                <vs-button class="red" @click="DeclineVerify(data)">Decline</vs-button>
              </tr>
            </tbody>
          </table>
        </div>
        <vs-pagination v-model="page" :length="pageCount" @input="updateData"></vs-pagination>
      </card>
    </div>



  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from './services/AuthenticationService';
const tableColumns = ["AreaName", "NickName", "From", "Amount", "Time", "Status"];
import Settings from "../settings.json";
import BaseSelect from '../components/BaseSelect.vue';



export default {
  components: {
    BaseTable,
    'base-select': BaseSelect,
  },
  props: {

  },
  data() {
    return {
      dm: Settings.BASE_URL,
      dataverify: [],
      itemsPerPage: 10,
      page: 1,
      searchInput: {
        areaname: "ALL",
      },
      optionsArea: [
        { value: "BIT", label: "BITTRADE" },
      ],

    };
  },
  computed: {
    pageCount() {

      return Math.ceil(this.dataverify.length / this.itemsPerPage);

    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.dataverify.slice(start, end);
    },
  },
  methods: {

    getVerify() {
      let areaName = this.searchInput.areaName;
      AuthenticationService.getListVerifing(areaName)
        .then((resp) => {
          if (resp.data.success) {
            this.dataverify = resp.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
    },
    updateData(page) {
      this.page = page;
    },
    AcceptVerify(data) {
      let obj = {
        type: "accept",
        id: data.id,
      }
      AuthenticationService.confirmKYCAccount(obj)
        .then((resp) => {
          if (resp.data.success) {

            return this.$vs.notification({
              text: 'Đã duyệt yêu cầu KYC ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        });
      this.getVerify()
    },
    DeclineVerify(data) {
      let obj = {
        type: "decline",
        id: data.id,
      }
      AuthenticationService.confirmKYCAccount(obj)
        .then((resp) => {
          if (resp.data.success) {

            return this.$vs.notification({
              text: 'Đã từ chối yêu cầu KYC ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        });
      this.getVerify()
    }

  }
}

</script>
<style>
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  font-weight: bold;
  background-color: #00172e;
  border-bottom: 2px solid #dee2e6;
}

.table tbody tr:nth-child(even) {
  background-color: #070025;
}
</style>
